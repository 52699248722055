import { type ElementType } from 'react'
import {
  typographyVariants,
  type TypographyVariantProps,
} from './typography.variants'

type AsProp<C extends React.ElementType> = {
  as?: C
}

type PropsToOmit<C extends React.ElementType, P> = keyof (AsProp<C> & P)

type PolymorphicComponentProp<
  C extends React.ElementType,
  Props = {},
> = React.PropsWithChildren<Props & AsProp<C>> &
  Omit<React.ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>

export type UseTypographyProps<C extends React.ElementType> =
  PolymorphicComponentProp<
    C,
    TypographyVariantProps & {
      secondColor?: string
    }
  >

const useTypography = <C extends ElementType = 'p'>(
  props: UseTypographyProps<C>
) => {
  const {
    className = '',
    as = 'p',
    fontSize = 'body-md',
    fontFamily = 'inter',
    fontWeight,
    lineHeight,
    color = '',
    spacing,
    uppercase = false,
    italic = false,
    ...componentProps
  } = props

  const styles = typographyVariants({
    fontSize,
    fontFamily,
    fontWeight,
    lineHeight,
    uppercase,
    italic,
    spacing,
  })

  return {
    className,
    styles,
    Component: as,
    color,
    ...componentProps,
  }
}

export { useTypography }
