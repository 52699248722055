import { tv, type VariantProps } from '../../lib/utils'

const typographyVariants = tv({
  slots: {
    base: ['text-pretty'],
  },
  variants: {
    fontSize: {
      ['heading-xl']: {
        base: ['text-6xl'],
      },
      ['heading-lg']: {
        base: ['text-3xl md:text-4xl'],
      },
      ['heading-md']: {
        base: ['text-2xl md:text-3xl lg:text-4xl'],
      },
      ['heading-sm']: {
        base: ['text-xl md:text-2xl lg:text-3xl'],
      },
      ['heading-xs']: {
        base: ['text-lg md:text-xl lg:text-2xl'],
      },
      ['heading-xxs']: {
        base: ['text-md md:text-lg lg:text-xl'],
      },
      ['body-xl']: {
        base: ['text-xl'],
      },
      ['body-lg']: {
        base: ['text-lg'],
      },
      ['body-md']: {
        base: ['text-base'],
      },
      ['body-sm']: {
        base: ['text-sm'],
      },
      ['body-xs']: {
        base: ['text-xs'],
      },
    },
    fontFamily: {
      inter: {
        base: ['font-inter'],
      },
      jakarta: {
        base: ['font-jakarta'],
      },
    },
    fontWeight: {
      light: {
        base: ['font-light'],
      },
      normal: {
        base: ['font-normal'],
      },
      medium: {
        base: ['font-medium'],
      },
      semibold: {
        base: ['font-semibold'],
      },
      bold: {
        base: ['font-bold'],
      },
    },
    spacing: {
      tighter: {
        base: ['tracking-tighter'],
      },
      tight: {
        base: ['tracking-tight'],
      },
      normal: {
        base: ['tracking-normal'],
      },
      wide: {
        base: ['tracking-wide'],
      },
      wider: {
        base: ['tracking-wider'],
      },
      widest: {
        base: ['tracking-widest'],
      },
    },
    lineHeight: {
      none: ['leading-none'],
      tight: ['leading-tight'],
      snug: ['leading-snug'],
      normal: ['leading-normal'],
      relaxed: ['leading-relaxed'],
      loose: ['leading-loose'],
    },
    uppercase: {
      false: {},
      true: {
        base: ['uppercase'],
      },
    },
    italic: {
      true: {
        base: ['italic'],
      },
    },
  },
  defaultVariants: {
    fontSize: 'body-md',
    fontFamily: 'inter',
    uppercase: false,
    italic: false,
  },
})

export type TypographyVariantProps = VariantProps<typeof typographyVariants>

export { typographyVariants }
