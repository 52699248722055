import { forwardRef } from 'react'
import { Loader2 } from 'lucide-react'
import { UseButtonProps, useButton } from './use-button'

export type ButtonProps = UseButtonProps

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    styles,
    className,
    Component,
    loadingLabel,
    isLoading,
    children,
    ...rest
  } = useButton(props)

  return (
    <Component ref={ref} className={styles.base({ className })} {...rest}>
      {isLoading ? (
        <>
          <Loader2 className={styles.loading()} /> {loadingLabel}
        </>
      ) : (
        children
      )}
    </Component>
  )
})

Button.displayName = 'Button'

export { Button }
