import { Children, forwardRef, type ElementType, type Ref } from 'react'
import { UseTypographyProps, useTypography } from './use-typography'
import { replaceWithMarkup } from '../../lib/utils'

const Typography = forwardRef(
  <C extends ElementType = 'p'>(
    props: UseTypographyProps<C>,
    ref: Ref<any>
  ) => {
    const {
      styles,
      className,
      Component,
      children,
      color,
      secondColor,
      ...rest
    } = useTypography(props)

    return (
      <Component
        ref={ref}
        className={styles.base({ className: `${className} ${color}` })}
        {...rest}
      >
        {Children.map(children, (child) => {
          if (
            typeof child === 'string' &&
            (child.includes('|') || child.includes('**'))
          ) {
            return (
              <span
                dangerouslySetInnerHTML={{
                  __html: replaceWithMarkup(child, secondColor),
                }}
              />
            )
          }

          return child
        })}
      </Component>
    )
  }
)

Typography.displayName = 'Typography'

export { Typography }
