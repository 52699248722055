import { tv, type VariantProps } from '../../lib/utils'
import { ghost, link, outline, solid } from './button.colors'

const buttonVariants = tv({
  slots: {
    base: [
      'inline-flex items-center justify-center',
      'whitespace-nowrap',
      'text-base',
      'transition-colors duration-300',
      'ring-offset-background',
      'focus-visible:outline-none',
      'focus-visible:ring-2 focus-visible:ring-offset-2',
      'disabled:pointer-events-none disabled:opacity-50',
    ],
    loading: ['mr-2', 'h-4 w-4', 'animate-spin'],
  },
  variants: {
    appearance: {
      solid: {},
      outline: {
        base: ['border', 'bg-transparent'],
      },
      ghost: {},
      link: {
        base: ['underline-offset-4', 'hover:underline'],
      },
    },
    color: {
      light: {
        base: ['focus-visible:ring-white/40'],
      },
      dark: {
        base: ['focus-visible:ring-gray-500/40'],
      },
      glass: {
        base: ['focus-visible:ring-gray-800/40'],
      },
      primary: {
        base: ['focus-visible:ring-primary-500/40'],
      },
      primaryLight: {
        base: ['focus-visible:ring-primary-500/40'],
      },
      secondary: {
        base: ['focus-visible:ring-secondary-500/40'],
      },
      orange: {
        base: ['focus-visible:ring-orange-500/40'],
      },
      red: {
        base: ['focus-visible:ring-red-500/40'],
      },
      whatsapp: {
        base: ['focus-visible:ring-whatsapp-100/40'],
      },
    },
    size: {
      sm: {
        base: ['text-sm'],
      },
      md: {
        base: ['text-base'],
      },
      lg: {
        base: ['text-lg'],
      },
    },
    fullWidth: {
      true: 'w-full',
      xxs: 'w-full xs:w-auto',
      xs: 'w-full sm:w-auto',
      sm: 'w-full md:w-auto',
      md: 'w-full lg:w-auto',
      lg: 'w-full xl:w-auto',
      mobile: 'w-full desktop:w-auto',
    },
    fontWeight: {
      light: {
        base: ['font-light'],
      },
      normal: {
        base: ['font-normal'],
      },
      medium: {
        base: ['font-medium'],
      },
      semibold: {
        base: ['font-semibold'],
      },
      bold: {
        base: ['font-bold'],
      },
    },
    uppercase: {
      true: {
        base: ['uppercase'],
      },
    },
    rounded: {
      false: {},
      true: {
        base: ['rounded-full'],
      },
    },
    isIcon: {
      false: {},
      true: {
        base: ['shrink-0'],
      },
    },
    isBetween: {
      true: 'w-full justify-between',
    },
    isLoading: {
      false: {},
      true: {},
    },
  },
  compoundVariants: [
    {
      appearance: ['solid', 'outline', 'ghost'],
      isIcon: false,
      className: {
        base: ['font-jakarta'],
      },
    },

    /**
     * Sizes
     */
    {
      appearance: ['solid', 'outline', 'ghost'],
      size: 'sm',
      isIcon: false,
      className: {
        base: ['h-10', 'px-4'],
      },
    },
    {
      appearance: ['solid', 'outline', 'ghost'],
      size: 'md',
      isIcon: false,
      className: {
        base: ['h-12', 'px-5'],
      },
    },
    {
      appearance: ['solid', 'outline', 'ghost'],
      size: 'lg',
      isIcon: false,
      className: {
        base: ['h-14', 'px-6'],
      },
    },

    /**
     * Rounded
     */
    {
      appearance: ['solid', 'outline', 'ghost'],
      size: 'sm',
      isIcon: false,
      rounded: false,
      className: {
        base: ['rounded-lg'],
      },
    },
    {
      appearance: ['solid', 'outline', 'ghost'],
      size: 'md',
      isIcon: false,
      rounded: false,
      className: {
        base: ['rounded-lg'],
      },
    },
    {
      appearance: ['solid', 'outline', 'ghost'],
      size: 'lg',
      isIcon: false,
      rounded: false,
      className: {
        base: ['rounded-lg'],
      },
    },

    /**
     * Sizes with icon
     */
    {
      appearance: ['solid', 'outline', 'ghost'],
      size: 'sm',
      isIcon: true,
      className: {
        base: ['h-10', 'w-10'],
      },
    },
    {
      appearance: ['solid', 'outline', 'ghost'],
      size: 'md',
      isIcon: true,
      className: {
        base: ['h-12', 'w-12'],
      },
    },
    {
      appearance: ['solid', 'outline', 'ghost'],
      size: 'lg',
      isIcon: true,
      className: {
        base: ['h-14', 'w-14'],
      },
    },

    /**
     * Colors
     */
    {
      appearance: 'solid',
      color: 'light',
      className: {
        base: solid.light,
      },
    },
    {
      appearance: 'solid',
      color: 'dark',
      className: {
        base: solid.dark,
      },
    },
    {
      appearance: 'solid',
      color: 'glass',
      className: {
        base: solid.glass,
      },
    },
    {
      appearance: 'solid',
      color: 'primary',
      className: {
        base: solid.primary,
      },
    },
    {
      appearance: 'solid',
      color: 'primaryLight',
      className: {
        base: solid.primaryLight,
      },
    },
    {
      appearance: 'solid',
      color: 'secondary',
      className: {
        base: solid.secondary,
      },
    },
    {
      appearance: 'solid',
      color: 'orange',
      className: {
        base: solid.orange,
      },
    },
    {
      appearance: 'solid',
      color: 'red',
      className: {
        base: solid.red,
      },
    },
    {
      appearance: 'solid',
      color: 'whatsapp',
      className: {
        base: solid.whatsapp,
      },
    },

    {
      appearance: 'outline',
      color: 'light',
      className: {
        base: outline.light,
      },
    },
    {
      appearance: 'outline',
      color: 'dark',
      className: {
        base: outline.dark,
      },
    },
    {
      appearance: 'outline',
      color: 'primary',
      className: {
        base: outline.primary,
      },
    },
    {
      appearance: 'outline',
      color: 'primaryLight',
      className: {
        base: outline.primaryLight,
      },
    },
    {
      appearance: 'outline',
      color: 'secondary',
      className: {
        base: outline.secondary,
      },
    },
    {
      appearance: 'outline',
      color: 'orange',
      className: {
        base: outline.orange,
      },
    },
    {
      appearance: 'outline',
      color: 'red',
      className: {
        base: outline.red,
      },
    },
    {
      appearance: 'outline',
      color: 'whatsapp',
      className: {
        base: outline.whatsapp,
      },
    },

    {
      appearance: 'ghost',
      color: 'light',
      className: {
        base: ghost.light,
      },
    },
    {
      appearance: 'ghost',
      color: 'dark',
      className: {
        base: ghost.dark,
      },
    },
    {
      appearance: 'ghost',
      color: 'primary',
      className: {
        base: ghost.primary,
      },
    },
    {
      appearance: 'ghost',
      color: 'primaryLight',
      className: {
        base: ghost.primaryLight,
      },
    },
    {
      appearance: 'ghost',
      color: 'secondary',
      className: {
        base: ghost.secondary,
      },
    },
    {
      appearance: 'ghost',
      color: 'orange',
      className: {
        base: ghost.orange,
      },
    },
    {
      appearance: 'ghost',
      color: 'red',
      className: {
        base: ghost.red,
      },
    },
    {
      appearance: 'ghost',
      color: 'whatsapp',
      className: {
        base: ghost.whatsapp,
      },
    },

    {
      appearance: 'link',
      color: 'light',
      className: {
        base: link.light,
      },
    },
    {
      appearance: 'link',
      color: 'dark',
      className: {
        base: link.dark,
      },
    },
    {
      appearance: 'link',
      color: 'primary',
      className: {
        base: link.primary,
      },
    },
    {
      appearance: 'link',
      color: 'primaryLight',
      className: {
        base: link.primaryLight,
      },
    },
    {
      appearance: 'link',
      color: 'secondary',
      className: {
        base: link.secondary,
      },
    },
    {
      appearance: 'link',
      color: 'orange',
      className: {
        base: link.orange,
      },
    },
    {
      appearance: 'link',
      color: 'red',
      className: {
        base: link.red,
      },
    },
    {
      appearance: 'link',
      color: 'whatsapp',
      className: {
        base: link.whatsapp,
      },
    },
  ],
  defaultVariants: {
    appearance: 'link',
    color: 'orange',
    size: 'md',
    fontWeight: 'semibold',
    uppercase: false,
    fullWidth: false,
    rounded: false,
    isBetween: false,
    isLoading: false,
  },
})

export type ButtonVariantProps = VariantProps<typeof buttonVariants>

export { buttonVariants }
