import { type ButtonHTMLAttributes } from 'react'
import { type LinkProps } from 'next/link'
import { Slot } from '@radix-ui/react-slot'
import { buttonVariants, type ButtonVariantProps } from './button.variants'

export type UseButtonProps = Omit<
  ButtonHTMLAttributes<HTMLButtonElement>,
  'color'
> &
  ButtonVariantProps & {
    asChild?: boolean
    loadingLabel?: string
    href?: LinkProps['href']
  }

const useButton = (props: UseButtonProps) => {
  const {
    className = '',
    appearance = 'link',
    color = 'orange',
    size = 'md',
    fontWeight = 'semibold',
    uppercase = false,
    fullWidth = false,
    rounded = false,
    disabled = false,
    isIcon = false,
    isBetween = false,
    isLoading = false,
    loadingLabel = 'Aguarde',
    asChild = false,
    ...componentProps
  } = props

  const styles = buttonVariants({
    appearance,
    color,
    size,
    fontWeight,
    uppercase,
    fullWidth,
    rounded,
    isIcon,
    isBetween,
    isLoading,
  })

  const Component = asChild ? Slot : 'button'

  return {
    className,
    styles,
    Component,
    loadingLabel,
    isLoading,
    disabled: disabled || isLoading,
    ...componentProps,
  }
}

export { useButton }
