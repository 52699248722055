export const solid = {
  light: ['bg-white', 'hover:bg-white/80', 'text-gray-400'],
  dark: ['bg-gray-800', 'hover:bg-gray-800/80', 'text-white'],
  glass: [
    'bg-black/40',
    'hover:bg-black/60',
    'text-white',
    'border border-white/30',
  ],
  primary: ['bg-primary-500', 'hover:bg-primary-500/80', 'text-white'],
  primaryLight: ['bg-primary-300', 'hover:bg-primary-300/80', 'text-white'],
  secondary: ['bg-secondary-500', 'hover:bg-secondary-500/80', 'text-white'],
  orange: ['bg-orange-500', 'hover:bg-orange-500/80', 'text-white'],
  red: ['bg-red-500', 'hover:bg-red-500/80', 'text-white'],
  whatsapp: ['bg-whatsapp-100', 'hover:bg-whatsapp-100/80', 'text-white'],
}

export const outline = {
  light: [
    'text-white',
    'border-white/40',
    'hover:bg-white',
    'hover:text-gray-400',
  ],
  dark: [
    'text-gray-400',
    'bg-white',
    'border-gray-100',
    'hover:text-white',
    'hover:bg-gray-800',
    'hover:border-gray-800',
  ],
  primary: [
    'text-primary-500',
    'border-primary-500',
    'hover:bg-primary-500',
    'hover:text-white',
  ],
  primaryLight: [
    'text-primary-300',
    'border-primary-300',
    'hover:bg-primary-300',
    'hover:text-white',
  ],
  secondary: [
    'text-secondary-500',
    'border-secondary-500',
    'hover:bg-secondary-500',
    'hover:text-white',
  ],
  orange: [
    'text-orange-500',
    'border-orange-500',
    'hover:bg-orange-500',
    'hover:text-white',
  ],
  red: [
    'text-red-500',
    'border-red-500',
    'hover:bg-red-500',
    'hover:text-white',
  ],
  whatsapp: [
    'text-whatsapp-100',
    'border-whatsapp-100',
    'hover:bg-whatsapp-100',
    'hover:text-white',
  ],
}

export const ghost = {
  light: ['text-white', 'hover:bg-white/10', 'hover:text-white'],
  dark: ['text-gray-800', 'hover:bg-gray-800/10', 'hover:text-gray-800'],
  primary: [
    'text-primary-500',
    'hover:bg-primary-500/10',
    'hover:text-primary-500',
  ],
  primaryLight: [
    'text-primary-300',
    'hover:bg-primary-300/10',
    'hover:text-primary-300',
  ],
  secondary: [
    'text-secondary-500',
    'hover:bg-secondary-500/10',
    'hover:text-secondary-500',
  ],
  orange: [
    'text-orange-500',
    'hover:bg-orange-500/10',
    'hover:text-orange-500',
  ],
  red: ['text-red-500', 'hover:bg-red-500/10', 'hover:text-red-500'],
  whatsapp: [
    'text-whatsapp-100',
    'hover:bg-whatsapp-100/10',
    'hover:text-whatsapp-100',
  ],
}

export const link = {
  light: ['text-white'],
  dark: ['text-gray-400 hover:text-gray-900'],
  primary: ['text-primary-500'],
  primaryLight: ['text-primary-300'],
  secondary: ['text-secondary-500'],
  orange: ['text-orange-500'],
  red: ['text-red-500'],
  whatsapp: ['text-whatsapp-100'],
}
